import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"bottom":"","right":"","transition":"scale-transition","origin":"top left"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VChip,_vm._g({},on),[_vm._v(" VueJS ")])]}}]),model:{value:(_vm.isMenuVisible),callback:function ($$v) {_vm.isMenuVisible=$$v},expression:"isMenuVisible"}},[_c(VList,[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("VueJS")]),_c(VListItemSubtitle,[_vm._v(" The Progressive JavaScript Framework ")])],1),_c(VListItemAction,[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.isMenuVisible = false}}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiClose))])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCombobox,{attrs:{"items":_vm.items,"chips":"","clearable":"","label":"Your favorite hobbies","multiple":"","prepend-icon":_vm.icons.mdiFilterVariant,"solo":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c(VChip,_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.remove(item)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item))]),_vm._v("  ")])]}}]),model:{value:(_vm.chips),callback:function ($$v) {_vm.chips=$$v},expression:"chips"}})}
var staticRenderFns = []

export { render, staticRenderFns }